<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="年份：">
          <el-date-picker v-model="form.year" format="yyyy" value-format="yyyy" type="year" placeholder="请选择年份" style="width:100px"> </el-date-picker>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="form.semester" placeholder="请选择" style="width:100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="春季学期" value="0"></el-option>
              <el-option label="秋季学期" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择" style="width:100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="未回复" value="0"></el-option>
              <el-option label="已回复" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名字、号码"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" stripe>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="学期" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row.year}}年{{scope.row.semester | semesterName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="区县" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row.cityName}}{{scope.row.streetName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="学校" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span >{{scope.row.schoolName}}{{scope.row.gradeName}}{{scope.row.className}}</span>
        </template>
      </el-table-column>      
      <el-table-column label="姓名"  align="center" show-overflow-tooltip>        
        <template slot-scope="scope">
          <span >{{scope.row.name || scope.row.alias}}</span>
        </template>
      </el-table-column>
      <el-table-column label="电话" prop="phone" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="最后联系时间" align="center"  prop="lastTime"></el-table-column>
      <el-table-column label="状态" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span class="blue" :class="{'red':scope.row.needReadCount>0}" >{{scope.row.needReadCount>0?'未回复':'已回复'}}</span>
        </template>
      </el-table-column>   
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-search" @click="showChat(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>


    <Chat v-if="isShowChat" v-model="isShowChat" :feedbackInfo="feedbackInfo" @load="getList" />

  
      
  </div>
</template>

<script>

import { Confirm } from '@/utils/common.js'
import {   getServiceFeedbackPageList } from '@/api/index.js'
import Chat from './chat.vue'
export default {
  name: 'feedback',
  components: { Chat },
  data() {
    return {
      isShowChat:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        year:'',
        semester:'',
        status:''
      },
      list: [],
      total: 0,
      feedbackInfo:{}
    }
    
  },
  created() {
    let nowDate = new Date();
    this.form.year = nowDate.getFullYear()+'';
    let month = nowDate.getMonth();
    if(month<6){
      this.form.semester = '0';
    }else{
      this.form.semester = '1';
    }
    this.getList();
  },
  methods: {
    showChat(info){
      this.feedbackInfo = info
      this.isShowChat = true;
    },
    getList() {
      getServiceFeedbackPageList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('确定删除该品牌吗?', () => {
        deleteBrand({ ids:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.blue{
  color:blue;
}
.red{
  color:red;
}
</style>