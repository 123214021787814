<template>
  <div>
    <el-dialog  :show-close="false"  :modal="false" :visible.sync="value" 
        :destroy-on-close="true" :close-on-click-modal="false" width="650px"
        >
      <template slot="title">
           <div class="title-box">
            <div class="call-status">{{feedbackInfo.name || feedbackInfo.alias}}</div>
            <div class="close" title="关闭" @click="beforeClose" ></div>
        </div>
      </template>
       
      <div class="chat-box">
        <div class="message-box" ref="messageListBox">
            <div v-for="(item,index) in messageList" :key="index" >
                    <div class="message-time" v-if="isShowMessageTime(item,index)">
                        <span class="time"> {{item.createTime | formateDate('yyyy年MM月dd日 HH时mm分')}}</span>                       
                    </div>
					<div class="item flex_col" :class=" item.sourceType == 1 ? 'push':'pull' ">
						<img :src="feedbackInfo.imageUrl" v-if="item.sourceType == 2" mode="aspectFill" class="pic" />
						<img src="@/assets/img/kefu-icon.jpeg"  v-else mode="aspectFill" class="pic" />
						<div class="content">{{item.messageContent}}</div>
					</div>
				</div>
        </div>
        <div class="order-box">
            <img :src="packageInfo.thumbnailList[0].url" />
            <div>{{orderInfo.year}}年{{orderInfo.semester|semesterName}}</div>
            <div>{{orderInfo.buyerInfor.cityName}}{{orderInfo.buyerInfor.regName}}</div>
            <div>{{orderInfo.buyerInfor.schoolName}}</div>
            <div>{{orderInfo.buyerInfor.gradeName}}{{orderInfo.buyerInfor.className}}</div>
            <div>{{orderInfo.buyerInfor.name}}</div>
            <div>{{orderInfo.buyerInfor.phone}}</div>
            <div>￥{{orderInfo.paymentMoney}}</div>
            <div>{{orderInfo.paymentTime}}</div>
        </div>         
      </div>
      <div class="send-box">
        <div class="input-box">
            <el-input resize="none"
                type="textarea"  :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请输入内容" style="border:0;"
                v-model="sendContext">
                </el-input>
        </div>
        <div class="send-btn">
            <div class="btn" @click="sendMsg()">发送</div>
        </div>
        
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import { getFeedbackList,getStuOrderInfo,addFeedback } from '@/api/index.js'

export default {
  name: 'chat',
  props:{
    value:{
      type:Boolean,
      default: false
    },
    feedbackInfo:{
        type: Object,
        default:()=>{}
    }
    
  },
  data() {
    return {
      messageList:[],
      orderInfo:{
        subscriptionInfor:{},
		buyerInfor:{}
      },
      packageInfo:{
        thumbnailList:[{url:''}]
      },
      sendContext:'',
      isSeed:false
      
    }
  },
  created() {    
    this.loadOrderInfo();
    this.getList();    
  },
  
  
  beforeDestroy(){

    
  },
  methods: {
    isShowMessageTime(currItem,index){
        if(index == 0){
            return true;
        }
        let currTime = currItem.createTime;
        let prevTime = this.messageList[index-1].createTime;
        return !this.$options.filters.checkTowDateInMinutes(currTime,prevTime)
    },
    sendMsg(){
        let _temp = this.sendContext.replace(/\s*/g, "")
        console.log('我是消息',_temp)
        if(_temp==''){
            this.$message.warning('不能发送空消息！')
            return;
        }
        let param = {            
            orderId:this.feedbackInfo.orderId,
            sourceType:1,
            messageType:'text',
            messageContent:this.sendContext,
            openId:this.feedbackInfo.openId,
            is_read:1      
        }
        addFeedback(param).then(()=>{
            param.createTime = this.$options.filters. getNowDate();
            this.messageList.push(param)
            this.sendContext = '';
            this.scrollBottom();	
            this.isSeed = true				 
        })
    },
    scrollBottom(){
        this.$nextTick(() => {
          // 通过 DOM API 更新文本
          const container = this.$refs.messageListBox;
      	container.scrollTop = container.scrollHeight+100;
        })
		
	},
    beforeClose(){
        if(this.isSeed){//刷新父级列表
            this.$emit('load');
        }
        this.$emit('input', false)
    },
    async getList(){
        let params = {
            oepenId:this.feedbackInfo.openId,
            orderId:this.feedbackInfo.orderId
        }
        const res = await getFeedbackList(params)
        this.messageList = res.data
        
        this.scrollBottom();
        
    },
    loadOrderInfo(){
        let params = {
            id:this.feedbackInfo.orderId
        }
        getStuOrderInfo(params).then((res)=>{
            this.orderInfo = res.data;
            this.packageInfo=res.data.subscriptionInfor.packageList[0]
        });
    },
    
    
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
    padding: 0px;
}
::v-deep .el-dialog__body{
    padding: 0 0 0 0 !important;
}
::v-deep .el-textarea__inner{
    border: 0 !important;
}
.title-box{
    width: 100%;
    display: flex;
    height: 35px;
    background-color: #2dab9f;
    .call-status{
        flex: 0 0 605px;
        color: #fff;
        text-align: left;
        padding-left:5px;
        line-height: 35px;
        font-size: 16px;
    }
    .close{
        flex: 1;
        background: url(../../../assets/img/close-fff.png) no-repeat center;
        background-size: 30px 30px;
        cursor: pointer;
    }
}
.chat-box{
    display: flex;
    height: 450px;
    background: #f8f8f8;
    .message-box{
        flex: 1;
        overflow-y: auto;
        padding-bottom: 50px;
        .message-time{
            margin-top: 10px;
            .time{
                background: #dadada;
                font-size:11px;
                color: #fff;
                padding: 5px;
                border-radius: 3px;
            }
        }
        .item{
			padding: 10px 10px 0 10px;
			align-items:flex-start;
			align-content:flex-start;
			color: #333;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
			
			.pic{
				width: 46px;
				height: 46px;
				border-radius: 50%;
				border: #fff solid 1px;
			}
			
			.content{
				padding: 10px;
				border-radius: 4px;
				max-width: 250px;
				word-break: break-all;
				line-height: 26px;
				position: relative;
				font-size:14px;
			}
			
			/* 收到的消息 */
			&.pull{
				.content{
					margin-left: 16px;
					background-color: #fff;
					
					&::after{
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
						border-right: 10px solid #fff;
						position: absolute;
						top: 15px;
						left: -9px;
					}
				}
			}
			
			/* 发出的消息 */
			&.push{
				/* 主轴为水平方向，起点在右端。使不修改DOM结构，也能改变元素排列顺序 */
				flex-direction: row-reverse;
				
				.content{
					margin-right: 16px;
					background-color: #a0e959;
					
					&::after{
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
						border-left: 10px solid #a0e959;
						position: absolute;
						top: 15px;
						right: -9px;
					}
				}
			}
		}
    }
    .order-box{
        flex: 0 0 150px;
        border-left: 3px solid #2dab9f;
        img{
            width:140px;
            height:140px;
        }
        div{
            height: 25px;
            font-size:14px;
            text-align: left;
            padding: 0px 5px;
        }
    }
}
.send-box{
    height: 100px;
    border-top: 3px solid #2dab9f;
    display: flex;
    .input-box{
        flex: 1;
        textarea{
            border: 0;
        }
    }
    .send-btn{
        flex: 0 0 150px;
         display: flex;
        align-items: center;
        justify-content: center;
        
        .btn{
            color: #fff;
            background: #2dab9f;
            width:70px;
            border-radius: 5px;
            line-height: 30px;
            cursor: pointer;
            
        }
    }
}



</style>